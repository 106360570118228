.pswp {
  --pswp-bg: #fff;
}

.icon-button {
  margin: 6px 8px 0 0;
  background: #f6f7f9;
  padding: 0px;
  border-radius: 8px;
  min-width: 36px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.icon-button .pswp__icn {
  width: 22px;
  height: 22px;
  position: initial;
  align-self: center;
}

.icon-button.close-button .pswp__icn {
  width: 16px;
  height: 16px;
}

.icon-button:hover {
  background: #f6f7f9;
}

.text-label {
  color: #2a2264;
  font-weight: 500;
  display: flex;
  width: 100%;
  align-items: center;
}

.pswp__top-bar {
  background-color: #fff;
  border: 1px solid #e7ebec;
  height: 50px;
}

.pswp__button--arrow {
  display: flex;
  border-radius: 50%;
  box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 13%);
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
}
.pswp__button--arrow:hover {
  box-shadow: 0px 6px 6px 0px rgb(0 0 0 / 13%);
}
.pswp__button--arrow--next {
  right: 16px;
}
.pswp__button--arrow--prev {
  left: 16px;
}
.pswp__error-msg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-error-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #303030;
  font-family: Circular TT Medium;
  margin: 30px 0px 12px 0px;
  text-align: center;
}

.media-error-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #979d9e;
  text-align: center;
}
