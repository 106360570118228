@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%; /* 1rem = 10px */
}

html.font-size-medium {
  font-size: 75%; /* 1rem = 12px */
}

html.font-size-large {
  font-size: 100%; /* 1rem = 16px */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Circular TT';
  src: url(./static/fonts/CircularTT/CircularTT-Book.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Circular TT Bold';
  src: url(./static/fonts/CircularTT/CircularTT-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Circular TT Medium';
  src: url(./static/fonts/CircularTT/CircularTT-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir Next';
  src: url(./static/fonts/AvenirNext/AvenirNextLTPro-Regular.otf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Avenir Next Bold';
  src: url(./static/fonts/AvenirNext/AvenirNextLTPro-Bold.otf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Avenir Next It';
  src: url(./static/fonts/AvenirNext/AvenirNextLTPro-It.otf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url(./static/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Tomato Grotesk Bold';
  src: url(./static/fonts/TomatoGrotesk/TomatoGrotesk-Bold.otf) format('truetype');
}

@font-face {
  font-family: "Suisse Int'l Semi Bold";
  src: url(./static/fonts/Suisse/SuisseIntl-SemiBold-WebM.woff) format('truetype');
}

@font-face {
  font-family: "Suisse Int'l Medium";
  src: url(./static/fonts/Suisse/SuisseIntl-Medium-WebM.woff) format('truetype');
}

@font-face {
  font-family: "Suisse Int'l Book";
  src: url(./static/fonts/Suisse/SuisseIntl-Book-WebM.woff2) format('truetype');
}

@font-face {
  font-family: 'Tomato Grotesk';
  font-weight: 700;
  src: local('Tomato Grotesk'),
    url('https://storage.googleapis.com/yalo-img/platform/assets/fonts/TomatoGrotesk-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Suisse Int';
  font-weight: 600;
  src: local('Suisse Int'),
    url('https://storage.googleapis.com/yalo-img/platform/assets/fonts/SuisseIntl-SemiBold-WebM.woff') format('woff');
}
@font-face {
  font-family: 'Suisse Int';
  font-weight: 500;
  src: local('Suisse Int'),
    url('https://storage.googleapis.com/yalo-img/platform/assets/fonts/SuisseIntl-Medium-WebM.woff') format('woff');
}
@font-face {
  font-family: 'Suisse Int';
  font-weight: 450;
  src: local('Suisse Int'),
    url('https://storage.googleapis.com/yalo-img/platform/assets/fonts/SuisseIntl-Book-WebM.woff') format('woff');
}
@font-face {
  font-family: 'Simplon Mono';
  font-weight: 400;
  src: local('Simplon Mono'),
    url('https://storage.googleapis.com/yalo-img/platform/assets/fonts/SimplonMono-Regular-WebTrial.woff')
      format('woff');
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #f2f2ff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f2f2ff;
}

.HSMHighlightText {
  color: #0e151c;
  flex-direction: row;
  align-items: center;
  padding: 3px 8px;
  gap: 8px;
  height: 2.4rem;
  background: #eef1f3;
  border-radius: 4px;
}
